<template>
  <v-toolbar dark color="primary">
    <v-btn icon :disabled="searchStarted" @click="index = 0">
      <v-icon>fas fa-backward</v-icon>
    </v-btn>
    <v-btn
      icon
      :disabled="searchStarted"
      @click="index = index > 0 ? index - 1 : report.assCount - 1"
    >
      <v-icon>fas fa-chevron-left</v-icon>
    </v-btn>
    <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
      <template v-slot:activator="{ on, attrs }">
        <v-toolbar-title v-bind="attrs" v-on="on"
        >{{ index + 1 }}/{{ report.assCount }}</v-toolbar-title
        >
      </template>
      <span>{{ index + 1 }}/{{ report.assCount }}</span>
    </v-tooltip>
    <v-btn
      icon
      :disabled="searchStarted"
      @click="index = index < report.assCount - 1 ? index + 1 : 0"
    >
      <v-icon>fas fa-chevron-right</v-icon>
    </v-btn>

    <v-btn icon :disabled="searchStarted" @click="index = report.assCount - 1">
      <v-icon>fas fa-forward</v-icon>
    </v-btn>
    <v-spacer v-if="$vuetify.breakpoint.xs" />
    <template v-if="$vuetify.breakpoint.xs" v-slot:extension>
      <Search_report :reports="reports" class="mb-2" />
      <v-spacer />
      <Report_type_dropdown />
      <!--      <v-menu bottom right>-->
      <!--        <template v-slot:activator="{ on }">-->
      <!--          <v-btn outlined v-on="on">-->
      <!--            <span>{{ assCount }}-{{ reportTypes[type].name }}</span>-->
      <!--            <v-icon right>fas fa-chevron-down</v-icon>-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--        <v-list>-->
      <!--          <v-list-item-group :value="type">-->
      <!--            <v-list-item-->
      <!--              v-for="({ name }, i) in new Array(...reportTypes)"-->
      <!--              :key="i"-->
      <!--              @click="typeChanged(i)"-->
      <!--            >-->
      <!--              <v-list-item-title>{{ name }}</v-list-item-title>-->
      <!--            </v-list-item>-->
      <!--          </v-list-item-group>-->
      <!--        </v-list>-->
      <!--      </v-menu>-->
    </template>
    <template v-if="$vuetify.breakpoint.smAndUp">
      <Search_report :reports="reports" />
      <v-spacer />
      <Report_type_dropdown />
    </template>
    <report-refresh @refresh="$emit('refresh')" />
    <export_to_pdf_btn clickable @click="exportToPdf"></export_to_pdf_btn>
    <print_button :html_element="el" />
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import { headers, headers_2, reportTypes } from "@/globals";
// import Mark from "mark.js";
import {
  dateFormat,
  formatDate,
  GetOnlyOpened,
  moneyFormat,
  processMoney
} from "@/functions";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import storage from "@/storage";
import PDF from "@/mixins/PDF";
// let MarkInstance = null;
export default {
  name: "report_toolbar",
  mixins: [PDF],
  components: {
    Report_type_dropdown: () =>
      import("@/components/report/report_type_dropdown"),
    ReportRefresh: () => import("@/components/report/reportRefresh"),
    Search_report: () => import("@/components/report/search_report"),
    print_button: () => import("@/components/print_button"),
    export_to_pdf_btn: () => import("@/components/export_to_pdf_btn")
  },
  data: () => ({
    reportTypes,
    highlighted: false,
    exportedData: [],
    labels: []
  }),
  computed: {
    index: {
      get() {
        return this.report.index;
      },
      set(val) {
        this.$store.commit("reportIndex", val);
      }
    },
    type: {
      get() {
        return this.report.type;
      },
      set(val) {
        this.$store.commit("reportType", val);
      }
    },
    searchStarted: {
      get() {
        return this.searchObj.started;
      },
      set(val) {
        this.$store.commit("searchStarted", val);
      }
    },
    ...mapGetters({
      report: "report",
      searchObj: "search",
      summary: "summaryData",
      db: "laudergetdb",
      selected: "lauderGetSelectedAss"
    })
  },
  methods: {
    exportName() {
      return (
        dateFormat() +
        this.reportTypes[this.reportTypes.findIndex(r => r.id === this.type)]
          .name
      );
    },
    prepareLabels() {
      const headersTotal = this.type < 3 ? headers : headers_2(this.type);
      this.labels = headersTotal.map(c => {
        return {
          field: c["value"],
          headerName: c["text"]
        };
      });
    },
    makeASingleReport(doc = jsPDF, report, pageWidth) {
      const marginX = 10;
      let body = [],
        headers = [];
      doc.setTextColor("#000");
      doc.setFontSize(20);
      doc.setFont("boldFont", "bold");
      doc.myText("Lueder,Larkin & Hunter", { align: "center" }, 0, 20);
      doc.setFont("normalFont", "normal");
      doc.setTextColor("#a7a7a7");
      doc.setFontSize(16);
      doc.myText(
        reportTypes[reportTypes.findIndex(r => r.id === this.report.type)].name,
        { align: "center" },
        0,
        40
      );
      doc.setFontSize(12);
      doc.setFillColor("#d4d4d4");
      doc.myRect(0, 50, 70, 15, "F", { align: "center", rx: 5, ry: 5 });
      doc.setTextColor("#000");
      doc.myText(dateFormat(), { align: "center" }, 0, 60);
      doc.setDrawColor("#d4d4d4");
      doc.line(10, 75, pageWidth - 10, 75, "F");
      doc.setFillColor("#fff");
      doc.myRect(10, 80, pageWidth - 20, 80, "FD");
      doc.setFontSize(10);
      doc.setFont("boldFont", "bold");
      doc.text("Client Name", 20, 70 + 25);
      doc.text("Collection Attorney", 20, 70 + 50);
      doc.text("Management Company", 20, 70 + 75);
      doc.text("Pre-Litigation Paralegal", pageWidth / 2, 70 + 25);
      doc.text("General Attorney", pageWidth / 2, 70 + 50);
      doc.text("Litigation Paralegal", pageWidth / 2, 70 + 75);
      doc.setFontSize(8);
      doc.setFont("boldFont", "bold");
      doc.setTextColor("#545454");
      const objectX = (w, key) => {
        const fontSize = doc.internal.getFontSize();
        const textSize =
          (doc.getStringUnitWidth(key) * fontSize) / doc.internal.scaleFactor;
        return w + textSize + 20;
      };
      doc.text(
        report["Client_Associations"],
        objectX(20, "Client Name"),
        70 + 25
      );
      doc.text(
        report["Collection Attorney"],
        objectX(20, "Collection Attorney"),
        70 + 50
      );
      doc.text(
        report["Management Company"],
        objectX(20, "Management Company"),
        70 + 75
      );
      doc.text(
        report["Pre-Litigation Paralegal"],
        objectX(pageWidth / 2, "Management Company"),
        70 + 25
      );
      doc.text(
        report["General Attorney"],
        objectX(pageWidth / 2, "General Attorney"),
        70 + 50
      );
      doc.text(
        report["Litigation Paralegal"],
        objectX(pageWidth / 2, "Litigation Paralegal"),
        70 + 75
      );
      let top;
      if (this.report.type < 3) {
        doc.setFillColor("#fff");
        doc.myRect(10, 170, pageWidth - 20, 160, "F");
        doc.setFontSize(10);
        doc.setFont("boldFont", "bold");
        doc.text("Owner Name:", 20, 170 + 25);
        doc.text("Property Address:", 20, 170 + 50);
        doc.text("Matter Reference:", 20, 170 + 75);
        doc.text("Matter Number:", 20, 170 + 100);
        doc.text("Status:", 20, 170 + 125);
        doc.text("Payments:", 20, 170 + 150);
        doc.setFontSize(8);
        doc.setFont("boldFont", "bold");
        doc.setTextColor("#545454");
        doc.text(report["Home Owner"], objectX(20, "Owner Name:"), 170 + 25);
        doc.text(
          report["Client Address"],
          objectX(20, "Property Address:"),
          170 + 50
        );
        doc.text(
          report["Matter Reference"],
          objectX(20, "Matter Reference:"),
          170 + 75
        );
        doc.text(
          report["Matter Number"],
          objectX(20, "Matter Number:"),
          170 + 100
        );
        doc.text(report["Status"], objectX(20, "Status:"), 170 + 125);
        doc.text(
          moneyFormat(report["payAmount"]),
          objectX(20, "Payments:"),
          170 + 150
        );
        top = 350;
        report.payments.forEach(employee => {
          let data = [];
          // if (dataIndex === 0) {
          //   headers.push("#");
          // }
          this.labels.forEach(label => {
            // if (index === 0) {
            //   data.push(dataIndex + 1);
            // }
            if (label["headerName"].toLowerCase().includes("date")) {
              data.push(formatDate(new Date(employee[label["field"]])));
            } else {
              data.push(employee[label["field"]]);
            }

            if (!headers.includes(label["headerName"])) {
              headers.push(label["headerName"]);
            }
          });
          body.push(data);
        });
        console.log(body);
      } else {
        top = 150;
        report.asses.forEach(employee => {
          let data = [];
          // if (dataIndex === 0) {
          //   headers.push("#");
          // }
          this.labels.forEach(label => {
            // if (index === 0) {
            //   data.push(dataIndex + 1);
            // }
            if (label["headerName"].toLowerCase().includes("date")) {
              data.push(formatDate(new Date(employee[label["field"]])));
            } else if (label["field"].includes("payAmount")) {
              data.push(moneyFormat(employee[label["field"]]));
            } else {
              data.push(employee[label["field"]]);
            }
            if (!headers.includes(label["headerName"])) {
              headers.push(label["headerName"]);
            }
          });
          body.push(data);
        });
      }
      if (this.$store.getters.report.type >= 3) {
        body.push([
          {
            content: `Total: ${report.payAmount}`,
            colSpan: 6,
            styles: {
              fontStyle: "bold",
              fontSize: 15,
              fillColor: [2, 119, 189],
              textColor: [255, 255, 255],
              cellPadding: 10
            }
          }
        ]);
      }
      autoTable(doc, {
        startY: top,
        tableWidth: pageWidth - marginX * 2,
        margin: { left: marginX, right: marginX },
        head: [headers],
        body,
        styles: {
          font: "boldFont",
          fontStyle: "bold"
        }
      });
    },
    async exportingData() {
      if (this.reports.length === 0) return [];
      if (this.report.type < 3) {
        if (this.report.type === 0) {
          return this.reports;
        } else {
          return GetOnlyOpened(this.reports);
        }
      } else {
        // console.table(filteredArray.map(r => r.count));
        // const record = filteredArray[this.report.index || 0];
        let reports = this.selected.AllAss
          ? await storage.getAllReports(this.db)
          : [
            await storage.getCertainReports(
              this.selected.Client_Associations,
              this.db,
              false
            )
          ];
        reports = reports.map(report => {
          //association data
          const contacts_data = report.asses[0];
          //field data
          const reportAssociations = report.asses.map(association => {
            const payments = association.payments;
            return {
              ...association,
              Client_Address: association["Client Address"],
              Home_Owner: association["Home Owner"],
              payAmount: payments.reduce(
                (a, b) => a + processMoney(b["Payment Amount"]),
                0
              ),
              Memo: payments.length > 0 ? payments[0].Memo : ""
            };
          });
          const totalPayment = moneyFormat(
            reportAssociations.reduce(
              (a, b) => a + processMoney(b["payAmount"]),
              0
            )
          );
          return {
            ...contacts_data,
            asses: reportAssociations,
            payAmount: totalPayment
          };
        });
        return reports;
      }
    },
    async exportToPdf() {
      try {
        this.loading = true;
        const doc = new jsPDF("p", "px", "A4");
        this.addFonts(doc);
        const reports = await this.exportingData();
        reports.forEach((report, i) => {
          let res,
            width = doc.internal.pageSize.getWidth();
          if (i === 0) {
            res = doc;
          } else {
            res = doc.addPage("A4", "p");
          }
          this.makeASingleReport(res, report, width);
        });
        doc.save(this.$options.filters.fileName(this.exportName()));
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    }
  },
  watch: {
    type: {
      immediate: true,
      handler() {
        this.prepareLabels();
      }
    }
  },
  props: {
    reports: {
      type: Array,
      default: () => []
    },
    el: {},
    elPdf: {}
  }
};
</script>

<style lang="scss">
span.highlight {
  background: mediumvioletred !important;
}
</style>
